import { BaseTextInput } from 'inputs'
import { OPERAZIONI_BASE } from '../../network/OperazioniBase'
import VisuraGenerica from '../VisuraGenerica'
import Persona from './Persona'

const NOMI = {
  CODICE_FISCALE_O_PATENTE: 'codiceFiscaleOPatente'
}

export default function VisuraPersona(props) {
  const { codiceFiscale, patente, importaPersona } = props

  // Manca il backend per ora
  return null

  return (
    <VisuraGenerica
      propsModal={{
        titolo: 'Visura anagrafica'
      }}
      propsContenutoModal={{
        defaultValues: {
          [NOMI.CODICE_FISCALE_O_PATENTE]: codiceFiscale || patente || ''
        },
        renderCampiForm: () => (
          <BaseTextInput
            label='Codice fiscale / patente'
            name={NOMI.CODICE_FISCALE_O_PATENTE}
            larghezza={20}
          />
        ),
        renderRisultatoVisura: persona => (
          <Persona
            persona={persona}
            espanso
            propsHeader={{ senzaPadding: false }}
            nascondiCittadinanza
          />
        ),
        useLogicaVisura: useLogicaVisuraPersona,
        importaDati: importaPersona,
      }}
    />
  )
}



function useLogicaVisuraPersona(props) {
  const { inviaOperazione, setRispostaVisura } = props

  async function eseguiVisura(valoriForm) {
    const { codiceFiscaleOPatente } = valoriForm

    const { ok, data } = await inviaOperazione(OPERAZIONI_BASE.VISURA_PERSONA, {
      codiceFiscaleOPatente
    }, {}, {
      mockResponse: {
        esito: true,
        data: { persona: PERSONA_TEST }
      }
    })

    if (ok) {
      const { persona } = data
      setRispostaVisura({ risultato: persona })
    }
  }

  return { eseguiVisura }
}



const PERSONA_TEST = {
  "uuid": "15a69642-7a66-4f8b-87a4-c23d36434399",
  "entitaRev": {
    "rev": 0,
    "revTs": 1677080326068,
    "revAutore": ""
  },
  "ruolo": "",
  "cognome": "Gattone",
  "nome": "Pierangelone",
  "sesso": "M",
  "codiceFiscale": "GTTPNG52H28F870S",
  "tipoDocumento": {
    "first": "patente",
    "second": "patente"
  },
  "senzaFissaDimora": false,
  "indirizzoResidenza": {
    "uuid": "0b3b9172-116b-4ea5-81c8-93f5e0e77332",
    "entitaRev": {
      "rev": 0,
      "revTs": 1677080326067,
      "revAutore": ""
    },
    "tipo": "via",
    "via": "Valverde",
    "cap": "25082",
    "nazione": "Italy",
    "comune": "Botticino",
    "tag": "",
    "provincia": "Brescia",
    "numeroCivico": "18",
    "presso": "",
    "fronteCivico": false,
    "idStradario": 0,
    "localizzazione": {
      "latitudine": 0,
      "longitudine": 0,
      "accuratezza": 0,
      "altitudine": 0
    },
    "quartiere": "",
    "domicilio": false,
    "tipoIntersezione": {
      "first": "",
      "second": ""
    }
  },
  "indirizzoDomicilio": {
    "uuid": "f23e6437-2806-4255-a860-3c328ca58974",
    "entitaRev": {
      "rev": 0,
      "revTs": 1679065028494,
      "revAutore": ""
    },
    "tipo": "via",
    "via": "cdd",
    "cap": "25080",
    "nazione": "Italy",
    "comune": "Moniga del Garda",
    "tag": "",
    "provincia": "Brescia",
    "numeroCivico": "",
    "presso": "ss",
    "fronteCivico": false,
    "idStradario": 0,
    "localizzazione": {
      "latitudine": 0,
      "longitudine": 0,
      "accuratezza": 0,
      "altitudine": 0
    },
    "quartiere": "qui",
    "domicilio": false,
    "tipoIntersezione": {
      "first": "",
      "second": ""
    }
  },
  "domicilioEqResidenza": false,
  "documento": {
    "tipoDocumento": {
      "first": "altroDocumento",
      "second": "Altro documento"
    },
    "rilasciatoDaAutorita": "uco",
    "rilasciatoDaLuogo": "ROMA",
    "dataRilascio": "2015-05-20",
    "dataPrimoRilascio": "1985-12-31",
    "dataScadenza": "2025-04-17",
    "patenteCategoria": "A3B",
    "patenteSospesa": false,
    "patenteRevocata": false,
    "patenteCQC": false,
    "patenteCQCTipo": {
      "first": "",
      "second": ""
    },
    "patenteSospensioneORevocaDesc": "",
    "numero": "UD0035255S",
    "tipoAltro": "kyrskyrsyrs",
    "cui": "",
    "note": "yoooooooooooooo"
  },
  "nascita": {
    "luogo": {
      "comune": "Putignano",
      "provincia": "Bari",
      "cap": "64015",
      "nazione": "Italia"
    },
    "dataNascita": "1952-06-28"
  },
  "pec": "vgvhcosi",
  "condizione": {
    "stato": "BUONA",
    "lesioni": "",
    "ricovero": "",
    "prognosi": "",
    "prognosiRiservata": false,
    "dettagliMorte": {
      "first": "",
      "second": ""
    }
  },
  "accertamentiEmatici": [],
  "testEtilometro": [
    {
      "uuid": "9d4e4364-13d0-4a9e-bf60-c50df6d30b2c",
      "entitaRev": {
        "rev": 0,
        "revTs": 1677080326068,
        "revAutore": ""
      },
      "pretest": true,
      "nome": "Pretest etilometro",
      "luogo": {
        "pressoComando": false,
        "sulPosto": true,
        "presso": ""
      },
      "scontrinoNum": "",
      "valoreAsStr": "",
      "valoreAsDuble": 0,
      "positivo": false,
      "effettuato": false,
      "ora": "2023-02-22T16:38:46.068+01:00",
      "documenti": [],
      "immagini": []
    },
    {
      "uuid": "7562503e-d9e3-4e71-a140-24b8b4b86aa0",
      "entitaRev": {
        "rev": 0,
        "revTs": 1677080326068,
        "revAutore": ""
      },
      "pretest": false,
      "nome": "Prova etilometro 1",
      "luogo": {
        "pressoComando": false,
        "sulPosto": true,
        "presso": ""
      },
      "scontrinoNum": "",
      "valoreAsStr": "",
      "valoreAsDuble": 0,
      "positivo": false,
      "effettuato": false,
      "ora": "2023-02-22T16:38:46.068+01:00",
      "documenti": [],
      "immagini": []
    },
    {
      "uuid": "4de6f688-0b1d-468b-afd4-9bfbf6cda7f4",
      "entitaRev": {
        "rev": 0,
        "revTs": 1677080326068,
        "revAutore": ""
      },
      "pretest": false,
      "nome": "Prova etilometro 2",
      "luogo": {
        "pressoComando": false,
        "sulPosto": true,
        "presso": ""
      },
      "scontrinoNum": "",
      "valoreAsStr": "",
      "valoreAsDuble": 0,
      "positivo": false,
      "effettuato": false,
      "ora": "2023-02-22T16:38:46.068+01:00",
      "documenti": [],
      "immagini": []
    }
  ],
  "contatti": {
    "email": "hhh",
    "telefono": "hhh",
    "relazioneConAltriSoggetti": "hhhh"
  },
  "cittadinanza": "ITALY",
  "cittadinanzaUnknownDescr": "",
  "anonimizzato": false,
  "minorenne": false,
  "statoCivile": "libero",
  "professione": "studente"
}