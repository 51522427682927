import Axios from 'axios'
import { atob } from 'js-base64'
import fileDownload from 'js-file-download'
import { calcolaFileNameConEstensione } from './MimeTypes'

function getData(url, opzioni = {}) {
  return Axios({
    url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      ...opzioni.headers
    },
    ...opzioni
  })
}

function postData(url, data = {}, opzioni = {}) {
  return Axios({
    url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      ...opzioni.headers
    },
    data,
    ...opzioni
  })
}

function deleteData(url, data = {}, opzioni = {}) {
  return Axios({
    url,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      ...opzioni.headers
    },
    data,
    ...opzioni
  })
}

function putData(url, data = {}, opzioni = {}) {
  return Axios({
    url,
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      ...opzioni.headers
    },
    data,
    ...opzioni
  })
}

function downloadFile(url, filename) {
  return Axios({
    url,
    method: 'get',
    responseType: 'blob',
  }).then(({ data }) => {
    const fileNameConEstensione = calcolaFileNameConEstensione(filename, data.type)
    fileDownload(data, fileNameConEstensione)
    return Promise.resolve(data)
  })
}

function apriLinkInNuovaTab(link) {
  window.open(link, '_blank', 'noreferrer')
}

function openFilePreviewFromUrl(url, filename) {
  return Axios({
    url,
    method: 'get',
    responseType: 'blob',
  }).then(({ data }) => {
    openFilePreviewFromBlob(data, filename)
  })
}

function openFilePreviewFromBlob(blob, filename) {
  const newWindow = window.open('', filename, 'width=1200,height=900')
  if (newWindow) {
    setTimeout(() => {
      const title = newWindow.document.createElement('title')
      title.appendChild(document.createTextNode(filename))
      newWindow.document.head.appendChild(title)

      const iframe = newWindow.document.createElement('iframe')
      const dataUrl = window.URL.createObjectURL(blob)
      iframe.setAttribute('src', dataUrl + '#zoom=125')
      iframe.setAttribute('width', '100%')
      iframe.setAttribute('height', '98%') // Evita la scrollbar verticale
      newWindow.document.body.appendChild(iframe)

      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(dataUrl)
      }, 100)
    }, 100)
  } else {
    alert('Finestra di anteprima bloccata dalle impostazioni del browser')
  }
}

function trasformaFileInBase64(file) {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const base64 = reader.result.substring(reader.result.indexOf(';base64,') + 8)
      return resolve({ ok: true, base64 })
    }
    reader.onerror = error => resolve({ ok: false, error })
  })
}

function convertBase64ToBlob(base64, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(base64)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}

function downloadFileBase64(base64, mimetype, filename) {
  const blob = convertBase64ToBlob(base64, mimetype)
  const fileNameConEstensione = calcolaFileNameConEstensione(filename, mimetype)
  fileDownload(blob, fileNameConEstensione)
}

// Prende un dataUrl "data:image/png;base64,gbdsf..." ed estrae il mimetype e il base64
function parseDataUrlBase64(dataUrl) {
  const indiceInizioMimetype = dataUrl.indexOf(':') + 1
  const indiceFineMimetype = dataUrl.indexOf(';')
  const mimetype = dataUrl.substring(indiceInizioMimetype, indiceFineMimetype)

  const indiceInizioBase64 = indiceFineMimetype + ';base64,'.length
  const base64 = dataUrl.substring(indiceInizioBase64)

  return { mimetype, base64 }
}

function mockNetworkRequest(mockResponse) {
  return new Promise(resolve => {
    setTimeout(() => resolve({ data: mockResponse }), 400)
  })
}

export {
  getData,
  postData,
  putData,
  deleteData,
  downloadFile,
  apriLinkInNuovaTab,
  openFilePreviewFromUrl,
  openFilePreviewFromBlob,
  trasformaFileInBase64,
  downloadFileBase64,
  parseDataUrlBase64,
  mockNetworkRequest
}