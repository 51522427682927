import { BaseButtons, BaseForm, BaseGridLayout, BaseTextInput, BaseTitolo,  BasePassword} from '@lucan/base-ui';
import MessageBox from 'components/MessageBox';
import Loading from 'components/Loading';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLogin } from 'services/Network';
import * as STATI from '../../constants/Status';

//import './../login.css';
//import { useUserContext } from '../services/UserContext';

const Login = ({app, app_key, onComplete}) => {

	const {
    inAttesaDelServer,
    invia
  } = useLogin({})
	
	const [stato, setStato] = useState('')
	const formRef = useRef()

	useEffect(() => {
   
      function keyboardListener(e) {
        if (e.key === 'Enter') formRef?.current?.submit()
      }

      window.addEventListener('keydown', keyboardListener)
      return () => window.removeEventListener('keydown', keyboardListener)
   
  }, [])
//


	async function eseguiTentativoLogin(credenziali) {
		const {username, password} = credenziali;
		const { ok, data, error:errorRichiesta} = await invia(username, password, app_key)
		if (ok){
			if(data.esito){
				onComplete(data)
			}else{
				setStato(data.messaggi[0].msg ?? '')
			}				
		}else{
			setStato(errorRichiesta.message);
		}		 
  }

 
	return(<>
			{app && <BaseTitolo><span style={{fontSize: '75%'}}>autenticazione per</span><br /><span className="titolo">{app}</span></BaseTitolo>}			
			<Loading loading={inAttesaDelServer} />
			{!inAttesaDelServer && stato && stato.length > 0 && <MessageBox  errorMsg={{main:stato}}/>}
			<BaseForm
				onSubmit={eseguiTentativoLogin}
				ref={formRef}
			>
				<BaseGridLayout vertical spacing={0}>
					<BaseTextInput  label='Nome utente' name='username' defaultValue='' required />
					<BasePassword   label='Password' name='password' defaultValue='' required />
					<BaseButtons.Login  className='full' />
				</BaseGridLayout>
			</BaseForm>
		</>)

}


export default Login;