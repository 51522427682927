import { useMemo } from 'react'
import { eliminaCampiInutili, getData, mockNetworkRequest, nonInProduzione, postData } from 'utils'
import { CAMPO_DA_SCARTARE } from '../form/CampiForm'
import { putData, deleteData } from 'utils/Network'

/**
 * @typedef {import("../../common/incloudApps/network/ImplementazioneRichiesteApiHook").UseImplementazioneRichiesteApiProps} UseImplementazioneRichiesteApiProps
 * @typedef {import("../../common/incloudApps/network/ImplementazioneRichiesteApiHook").useImplementazioneRichiesteApi} useImplementazioneRichiesteApi
 * @typedef {import("../../common/incloudApps/network/ImplementazioneRichiesteApiHook").inviaRichiesta} inviaRichiesta
 */

/**
 * @type {useImplementazioneRichiesteApi}
 */
export default function useImplementazioneRichiesteApi(ROTTA_BASE_API, URL_SERVER, MAPPA_ENDPOINTS, nomeApp, versione, OPERAZIONI_CONFIG) {
  return useMemo(() => {
    function costruisciRottaApi(endpoint) {
      // La ROTTA_BASE_API può essere passata in 2 modi:
      // - completa con URL_SERVER (es. "https://alfa.incloudteam.com/jconnet-v2/api/v2/")
      // - senza URL_SERVER (es. "/api/v2/")
      return (ROTTA_BASE_API.startsWith(URL_SERVER))
        ? ROTTA_BASE_API + endpoint
        : URL_SERVER + ROTTA_BASE_API + endpoint
    }

    const {
      LOGIN_CON_CREDENZIALI = 'login',
      RICHIESTA_OPERAZIONE = 'op',
      ALLEGATO = 'attachment'
    } = MAPPA_ENDPOINTS ?? {}

    /**
     * @type {inviaRichiesta}
     */
    function inviaRichiesta(endpoint, corpoRichiesta, opzioni = {}) {
      const { mockResponse, ...restOpzioni } = opzioni
      const promise = mockResponse
        ? mockNetworkRequest(mockResponse)
        : postData(costruisciRottaApi(endpoint), corpoRichiesta, restOpzioni)

      return promise.then(({ data }) => {
        if (nonInProduzione()) console.log(data)
        if (data.errorMsg) {
          data.messaggi = [{ msg: data.errorMsg, codice: data.errorMsg }]
        }
        return data.esito ? Promise.resolve(data.data || data) : Promise.reject(data)
      })
    }

    const application = `${nomeApp} ${versione}`

    function inviaRichiestaLogin_Implementazione(credenziali) {
      return inviaRichiesta(LOGIN_CON_CREDENZIALI, {
        ...credenziali,
        application
      })
    }

    function inviaOperazione_Implementazione(opId, userInfo, data, opzioni = {}) {
      const { comando, token, info = {} } = userInfo
      const { nickname = '' } = info
      let corpoRichiesta = {
        opId,
        username: nickname || '',
        comando: comando || '',
        token,
        application
      }

      if (data) corpoRichiesta.data = eliminaCampiInutili(data, CAMPO_DA_SCARTARE)

      // Aggiungo l'opId nella queryString per distinguere facilmente le operazioni nell'inspector
      const opzioniDefinitive = {
        ...opzioni,
        params: { id: opId, ...opzioni.params }
      }

      return inviaRichiesta(RICHIESTA_OPERAZIONE, corpoRichiesta, opzioniDefinitive)
    }

    function getAttachmentURL_Implementazione(uuid, userInfo, thumb = false, original = false) {
      const attachmentURL = new URL(costruisciRottaApi(ALLEGATO), window.location.origin)

      const { comando, token } = userInfo
      const params = attachmentURL.searchParams
      params.append('uuid', uuid)
      if (comando) params.append('cmd', comando)
      params.append('token', token)
      params.append('thumb', String(thumb))
      if (original) params.append('original', String(original))

      return attachmentURL.href
    }

    // partialURL non contiene la ROTTA_BASE_API e include già  parametri 'uuid' e 'thumb'
    function getAttachmentURLfromPartialURL_Implementazione(partialURL, userInfo) {
      const attachmentURL = new URL(URL_SERVER + partialURL, window.location.origin)

      const { comando, token } = userInfo
      const params = attachmentURL.searchParams
      if (comando) params.append('cmd', comando)
      params.append('token', token)

      return attachmentURL.href
    }

    /*************** Nuova gestione ***************/

    function costruisciRottaApi_NUOVA(endpoint, rottaBaseApi) {
      // La rottaBaseApi può essere passata in 2 modi:
      // - completa con URL_SERVER (es. "https://alfa.incloudteam.com/jconnet-v2/api/v2/")
      // - senza URL_SERVER (es. "/api/v2/")
      const rotta = rottaBaseApi + endpoint
      return rottaBaseApi.startsWith(URL_SERVER) ? rotta : URL_SERVER + rotta
    }

    function inviaOperazione_PuntoIngresso(opId, userInfo, data, opzioni) {
      const config = OPERAZIONI_CONFIG[opId]
      return config
        ? inviaRichiesta_NUOVA(userInfo, data, opzioni, config)
        : inviaOperazione_Implementazione(opId, userInfo, data, opzioni)
    }

    function sostituisciParametriUrl(url, data) {
      if (!(data && Object.keys(data).length > 0)) {
        return url
      }
      let urlSegmentiSostiuiti = []
      const urlSegmenti = url.split('/')
      urlSegmenti.forEach(element => {
        let newElement = element
        if (element.startsWith(':')) {
          const key = element.slice(1)
          // eslint-disable-next-line no-prototype-builtins
          if (data && data.hasOwnProperty(key)) {
            if (typeof data[key] === 'string' || typeof data[key] === 'number') {
              newElement = data[key]
            } else {
              console.warn('impossibile sostiture: :' + key, data[key])
            }
          } else {
            console.warn('elemento non trovato: :' + key, data)
          }
        }
        urlSegmentiSostiuiti.push(newElement)
      })
      return urlSegmentiSostiuiti.join('/')
    }


    function inviaRichiesta_NUOVA(userInfo, data, opzioni = {}, config = {}) {
      const {
        mockResponse,
        urlParams = null,
        ...restOpzioni
      } = opzioni

      const {
        httpMethod = 'post',
        rottaBaseApi = ROTTA_BASE_API,
        endpoint
      } = config

      const url = costruisciRottaApi_NUOVA(endpoint, rottaBaseApi)

      const corpoRichiesta = {
        ...(data && eliminaCampiInutili(data, CAMPO_DA_SCARTARE))
      }

      const { comando = '', token, info = {} } = userInfo
      const { nickname = '' } = info
      const opzioniRichiesta = {
        ...restOpzioni,
        headers: {
          'X-Comando': comando,
          'X-Username': nickname,
          'Authorization': `Bearer ${token}`,
          ...restOpzioni.headers
        }
      }

      let promessa
      const fixedUrl = sostituisciParametriUrl(url, urlParams)

      if (mockResponse) {
        promessa = mockNetworkRequest(mockResponse)
      } else if (httpMethod === 'get') {
        data && console.warn('Data ignorato usare 4° parametro {urlParams:...}', data)
        promessa = getData(fixedUrl, opzioniRichiesta)
      } else if (httpMethod === 'post') {
        promessa = postData(fixedUrl, corpoRichiesta, opzioniRichiesta)
      } else if (httpMethod === 'put') {
        promessa = putData(fixedUrl, corpoRichiesta, opzioniRichiesta)
      } else if (httpMethod === 'delete') {
        promessa = deleteData(fixedUrl, corpoRichiesta, opzioniRichiesta)
      }

      return promessa.then(({ data }) => data)
    }

    /*************** Nuova gestione ***************/

    return {
      inviaRichiestaLogin_Implementazione,
      inviaOperazione_Implementazione: inviaOperazione_PuntoIngresso,
      getAttachmentURL_Implementazione,
      getAttachmentURLfromPartialURL_Implementazione
    }
  }, [MAPPA_ENDPOINTS, ROTTA_BASE_API, URL_SERVER, nomeApp, versione, OPERAZIONI_CONFIG])
}


