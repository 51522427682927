import { BaseTextInput, BaseSelect } from 'inputs'
import { BaseGridLayout } from 'layout'
import ElenchiManager, { ELENCHI } from '../../form/ElenchiManager'
import { OPERAZIONI_BASE } from '../../network/OperazioniBase'
import VisuraGenerica from '../VisuraGenerica'
import Veicolo from './Veicolo'

const NOMI = {
  TARGA: 'targa',
  TIPO_VISURA: 'tipoVisura',
  TIPO_SERVIZIO: 'tipoServizio',
  TIPO_VEICOLO: 'tipoVeicolo'
}

export default function VisuraVeicolo(props) {
  const { targa, importaVeicolo } = props

  // Manca il backend per ora
  return null

  return (
    <ElenchiManager
      elenchi={[ELENCHI.VISURE_CONFIG, ELENCHI.AUTOVETTURA_TIPO]}
      render={el => {
        const { tipiVisura, tipiServizio } = el(ELENCHI.VISURE_CONFIG)
        const tipiVeicolo = el(ELENCHI.AUTOVETTURA_TIPO)
        return (
          <VisuraGenerica
            propsModal={{
              titolo: 'Visura veicolo'
            }}
            propsContenutoModal={{
              defaultValues: {
                [NOMI.TARGA]: targa || '',
                [NOMI.TIPO_VISURA]: tipiVisura[0].value,
                [NOMI.TIPO_SERVIZIO]: tipiServizio[0].value,
                [NOMI.TIPO_VEICOLO]: tipiVeicolo[0].value,
              },
              renderCampiForm: () => (
                <BaseGridLayout>
                  <BaseTextInput
                    label='Targa' name={NOMI.TARGA}
                  />
                  <BaseSelect
                    label='Tipo visura' name={NOMI.TIPO_VISURA}
                    options={tipiVisura}
                  />
                  <BaseSelect
                    label='Servizio' name={NOMI.TIPO_SERVIZIO}
                    options={tipiServizio}
                  />
                  <BaseSelect
                    label='Tipo veicolo' name={NOMI.TIPO_VEICOLO}
                    options={tipiVeicolo}
                  />
                </BaseGridLayout>
              ),
              renderRisultatoVisura: veicolo => (
                <Veicolo
                  veicolo={veicolo}
                  espanso
                  propsHeader={{ senzaPadding: false }}
                  nascondiPasseggeri
                />
              ),
              useLogicaVisura: useLogicaVisuraVeicolo,
              importaDati: importaVeicolo
            }}
          />
        )
      }}
    />
  )
}



function useLogicaVisuraVeicolo(props) {
  const { inviaOperazione, setRispostaVisura } = props

  async function eseguiVisura(valoriForm) {
    const { targa, tipoVisura, tipoServizio, tipoVeicolo } = valoriForm

    const { ok, data } = await inviaOperazione(OPERAZIONI_BASE.VISURA_VEICOLO, {
      targa, tipoVisura, tipoServizio, tipoVeicolo
    }, {}, {
      mockResponse: {
        esito: true,
        data: { veicolo: VEICOLO_TEST }
      }
    })
    if (ok) {
      const { veicolo } = data
      setRispostaVisura({ risultato: veicolo })
    }
  }

  return { eseguiVisura }
}



const VEICOLO_TEST = {
  "uuid": "9c7c8cf7-fb0e-4f22-9461-b8b3943f837a",
  "entitaRev": {
    "rev": 0,
    "revTs": 1684492808249,
    "revAutore": ""
  },
  "targa": "AB123CC",
  "targaProva": false,
  "targaAssente": false,
  "nazione": {
    "first": "Italy",
    "second": "Italy"
  },
  "tipo": {
    "first": "AUTOVETTURA",
    "second": "AUTOVETTURA"
  },
  "telaio": "SSA00000434169484",
  "proprietario": {
    "uuid": "67d21a1e-9fc4-48f7-b894-0868332f87ea",
    "entitaRev": {
      "rev": 0,
      "revTs": 1684492808248,
      "revAutore": ""
    },
    "persona": {
      "uuid": "a43d0f58-fa79-49eb-a0f4-11afef80037f",
      "entitaRev": {
        "rev": 0,
        "revTs": 1684492811534,
        "revAutore": ""
      },
      "ruolo": "Proprietario veicolo",
      "cognome": "D'Iorio",
      "nome": "Domenico",
      "sesso": "M",
      "codiceFiscale": "DRIDNC50D04H272R",
      "tipoDocumento": {
        "first": "patente",
        "second": "patente"
      },
      "senzaFissaDimora": false,
      "indirizzoResidenza": {
        "uuid": "a52123a3-5885-483e-8c5e-9422d720c71f",
        "entitaRev": {
          "rev": 0,
          "revTs": 1684492811533,
          "revAutore": ""
        },
        "tipo": "via",
        "via": "Statale M.",
        "cap": "71017",
        "nazione": "Italy",
        "comune": "Torremaggiore",
        "tag": "",
        "provincia": "Foggia",
        "numeroCivico": "545",
        "presso": "",
        "fronteCivico": false,
        "idStradario": 0,
        "localizzazione": {
          "latitudine": 0,
          "longitudine": 0,
          "accuratezza": 0,
          "altitudine": 0
        },
        "quartiere": "",
        "domicilio": false,
        "isDomicilioTemporaneo": false,
        "tipoIntersezione": {
          "first": "",
          "second": ""
        }
      },
      "domicilioEqResidenza": true,
      "documento": {
        "tipoDocumento": {
          "first": "patente",
          "second": "Patente"
        },
        "rilasciatoDaAutorita": "mctc",
        "rilasciatoDaLuogo": "RO",
        "dataRilascio": "2015-05-20",
        "dataPrimoRilascio": "1985-12-31",
        "dataScadenza": "2025-04-17",
        "patenteCategoria": "A3B",
        "patenteSospesa": false,
        "patenteRevocata": false,
        "patenteCQC": false,
        "patenteCQCTipo": {
          "first": "",
          "second": ""
        },
        "patenteSospensioneORevocaDesc": "",
        "numero": "RO0043403R",
        "tipoAltro": "",
        "cui": "",
        "note": ""
      },
      "nascita": {
        "luogo": {
          "comune": "Torremaggiore",
          "provincia": "Foggia",
          "cap": "15010",
          "nazione": "Italia"
        },
        "dataNascita": "1950-04-04"
      },
      "pec": "",
      "condizione": {
        "stato": "BUONA",
        "lesioni": "",
        "ricovero": "",
        "prognosi": "",
        "prognosiRiservata": false,
        "dettagliMorte": {
          "first": "",
          "second": ""
        },
        "luogoMorte": "",
        "riconoscimentoSalma": "",
        "destinazioneSalma": {
          "first": "",
          "second": ""
        }
      },
      "accertamentiEmatici": [],
      "testEtilometro": [
        {
          "uuid": "69e0d2bb-e2b3-4b2f-9fc2-a04d72a1ed6b",
          "entitaRev": {
            "rev": 0,
            "revTs": 1684492811534,
            "revAutore": ""
          },
          "pretest": true,
          "nome": "Pretest etilometro",
          "luogo": {
            "pressoComando": false,
            "sulPosto": true,
            "presso": ""
          },
          "scontrinoNum": "",
          "valoreAsStr": "",
          "valoreAsDuble": 0,
          "positivo": false,
          "effettuato": false,
          "ora": "2023-05-19T12:40:11.534+02:00",
          "documenti": [],
          "immagini": []
        },
        {
          "uuid": "626bbdd4-6005-458b-83a8-d9650e95997f",
          "entitaRev": {
            "rev": 0,
            "revTs": 1684492811534,
            "revAutore": ""
          },
          "pretest": false,
          "nome": "Prova etilometro 1",
          "luogo": {
            "pressoComando": false,
            "sulPosto": true,
            "presso": ""
          },
          "scontrinoNum": "",
          "valoreAsStr": "",
          "valoreAsDuble": 0,
          "positivo": false,
          "effettuato": false,
          "ora": "2023-05-19T12:40:11.534+02:00",
          "documenti": [],
          "immagini": []
        },
        {
          "uuid": "8782b827-f141-430a-85e9-ad047caee9c4",
          "entitaRev": {
            "rev": 0,
            "revTs": 1684492811534,
            "revAutore": ""
          },
          "pretest": false,
          "nome": "Prova etilometro 2",
          "luogo": {
            "pressoComando": false,
            "sulPosto": true,
            "presso": ""
          },
          "scontrinoNum": "",
          "valoreAsStr": "",
          "valoreAsDuble": 0,
          "positivo": false,
          "effettuato": false,
          "ora": "2023-05-19T12:40:11.534+02:00",
          "documenti": [],
          "immagini": []
        }
      ],
      "contatti": {
        "email": "",
        "telefono": "",
        "relazioneConAltriSoggetti": ""
      },
      "cittadinanza": "ITALY",
      "cittadinanzaUnknownDescr": "",
      "anonimizzato": false,
      "minorenne": false,
      "statoCivile": "",
      "professione": "",
      "luogoLavoro": ""
    }
  },
  "anagrafiche": [],
  "veicoloNomi": {
    "fabbrica": "FIAT",
    "tipo": "SEICENTO 1.1I",
    "serie": "",
    "extra": ""
  },
  "passeggeri": [],
  "assicurazione": {
    "stato": "IL VEICOLO RISULTA ASSICURATO.",
    "compagnia": "ASSICURAZIONE TRADE",
    "agenzia": "",
    "numero": "1234567890",
    "dataAssicurazione": "2022-07-28",
    "dataScadenza": "2023-08-18",
    "dataFineComporto": "2024-10-14"
  },
  "revisione": {
    "dataRevisione": "2022-09-21",
    "dataScadenza": "2024-09-30"
  },
  "posizione": {
    "uuid": "8a6269e2-f930-45a1-8ef5-d8726cbb797e",
    "entitaRev": {
      "rev": 0,
      "revTs": 1684492808249,
      "revAutore": ""
    },
    "posizione": {
      "first": "",
      "second": ""
    },
    "note": ""
  },
  "conducenteEProprietario": true,
  "destinazione": "AUTOVETTURA PER TRASPORTO DI PERSONE",
  "uso": "PROPRIO",
  "specifica": "",
  "alimentazione": {
    "first": "",
    "second": ""
  },
  "latoGuida": {
    "first": "",
    "second": ""
  },
  "mcpc": "1200",
  "carico": "",
  "dataImmatricolazione": "2011-05-03",
  "messaggi": [
    {
      "codice": "NONE",
      "livello": "ERROR",
      "msg": "Nessuna autorizzazione alla sosta",
      "percorso": "",
      "path": ""
    },
    {
      "codice": "NONE",
      "livello": "WARN",
      "msg": "ATTENZIONE: RISULTATI GENERATI CASUALMENTE PER TEST DEL PROGRAMMA!",
      "percorso": "",
      "path": ""
    },
    {
      "codice": "NONE",
      "livello": "INFO",
      "msg": "IL VEICOLO RISULTA ASSICURATO",
      "percorso": "",
      "path": ""
    },
    {
      "codice": "NONE",
      "livello": "INFO",
      "msg": "REVISIONE VALIDA",
      "percorso": "",
      "path": ""
    }
  ]
}