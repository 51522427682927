import { useFormContext } from 'react-hook-form'
import { BaseTextInput, BaseDateInput, BaseSelect, BaseCheckbox } from 'inputs'
import { BaseScatolaConTitolo, BaseGridLayout } from 'layout'
import { isCodiceFiscaleValido, isEmailValida } from 'utils'
import ElenchiManager, { ELENCHI } from '../../form/ElenchiManager'
import { CHIAVI_CONTESTO_VALIDAZIONE } from '../ContestoValidazione'
import { ENTITA_BASE } from '../EntitaBase'
import FormModificaEntita, { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'
import ComuneModifica from '../indirizzo/ComuneModifica'
import SceltaNazione from '../indirizzo/SceltaNazione'
import { isItaly, ITALY } from '../indirizzo/utilIndirizzo'
import VisuraPersona from './VisuraPersona'

DatiAnagraficiModifica.propTypes = PROP_TYPES_WRAPPER_FORM_MODIFICA

export default function DatiAnagraficiModifica(props) {
  const { propsEntita, label, importaPersona, modalitaLight } = props
  const { valoriInput, pathEntita } = propsEntita
  const isCognomeVuoto = (valoriInput.cognome === '')

  return (
    <ElenchiManager
      elenchi={[ELENCHI.NAZIONI, ELENCHI.SESSO]}
      render={el => (
        <BaseGridLayout vertical>
          <BaseScatolaConTitolo titolo={label}>
            <FormModificaEntita
              {...propsEntita}
              trasformaValoriInput={trasformaValoriInputDatiAnagrafici}
            >
              <FormDatiAnagrafici
                sessi={el(ELENCHI.SESSO)}
                nazioni={el(ELENCHI.NAZIONI)}
                isCognomeVuoto={isCognomeVuoto}
                importaPersona={importaPersona}
                modalitaLight={modalitaLight}
              />
            </FormModificaEntita>
          </BaseScatolaConTitolo>

          <BaseScatolaConTitolo titolo='Nascita'>
            <FormModificaEntita
              {...propsEntita}
              valoriInput={valoriInput.nascita}
              pathEntita={pathEntita.addPath('nascita')}
              nomeEntita={ENTITA_BASE.PERSONA_NASCITA}
              trasformaValoriInput={trasformaValoriInputNascita}
            >
              <FormNascita nazioni={el(ELENCHI.NAZIONI)} />
            </FormModificaEntita>
          </BaseScatolaConTitolo>
        </BaseGridLayout>
      )}
    />
  )
}



const NOMI = {
  NOME: 'nome',
  COGNOME: 'cognome',
  CODICE_FISCALE: 'codiceFiscale',
  CITTADINANZA: 'cittadinanza',
  CITTADINANZA_UNKNOWN_DESCR: 'cittadinanzaUnknownDescr',
  SESSO: 'sesso',
  MINORENNE: 'minorenne',
  STATO_CIVILE: 'statoCivile',
  PROFESSIONE: 'professione',

  // Il campo pec non è mostrato qui ma nei contatti
  PEC: 'pec'
}

export function FormDatiAnagrafici(props) {
  const { sessi, nazioni, isCognomeVuoto, importaPersona, modalitaLight } = props

  const { watch } = useFormContext()
  const [cittadinanza, codiceFiscale] = watch([NOMI.CITTADINANZA, NOMI.CODICE_FISCALE])
  const isCittadinanzaUnknown = cittadinanza.toUpperCase() === 'UNKNOWN'

  return (
    <BaseGridLayout disposizioneRighe={[4, 4, 3]} alignCenter>
      <BaseTextInput
        label='Cognome' name={NOMI.COGNOME}
        autoFocus={isCognomeVuoto}
      />
      <BaseTextInput
        label='Nome' name={NOMI.NOME}
      />
      <BaseTextInput
        label='Codice fiscale' name={NOMI.CODICE_FISCALE}
      />
      <VisuraPersona
        codiceFiscale={codiceFiscale}
        importaPersona={importaPersona}
      />

      <BaseSelect
        label='Sesso' name={NOMI.SESSO}
        options={sessi}
      />
      <SceltaNazione
        label='Cittadinanza' name={NOMI.CITTADINANZA}
        options={nazioni}
      />
      <BaseTextInput
        label='Specificare cittadinanza' name={NOMI.CITTADINANZA_UNKNOWN_DESCR}
        hidden={!isCittadinanzaUnknown}
      />
      <BaseCheckbox
        label='Minorenne' name={NOMI.MINORENNE}
        hidden={modalitaLight}
      />

      <BaseTextInput
        label='Stato civile' name={NOMI.STATO_CIVILE}
        hidden={modalitaLight}
      />
      <BaseTextInput
        label='Professione' name={NOMI.PROFESSIONE}
        hidden={modalitaLight}
      />
      <></>
    </BaseGridLayout>
  )
}

export function trasformaValoriInputDatiAnagrafici(valori) {
  const { sesso } = valori

  if (sesso === 'm' || sesso === 'f') {
    return {
      ...valori,
      sesso: sesso.toUpperCase()
    }
  }

  return valori
}

export function validaPersonaDatiAnagrafici(utilValidazione) {
  const { contesto, required, warn, valoriInput, getUtilValidazioneAnnidato } = utilValidazione
  const { codiceFiscale, pec } = valoriInput

  required('Nome non inserito', NOMI.NOME)
  required('Cognome non inserito', NOMI.COGNOME)

  if (contesto[CHIAVI_CONTESTO_VALIDAZIONE.OBBLIGO_CODICE_FISCALE] === true) {
    required('Codice fiscale non inserito', NOMI.CODICE_FISCALE)
  }

  if (!isCodiceFiscaleValido(codiceFiscale)) {
    warn('Codice fiscale non valido', NOMI.CODICE_FISCALE)
  }
  // Devo farlo qui e non nei contatti perché la pec è salvata al primo livello della Persona
  if (!isEmailValida(pec)) {
    warn('PEC non valida', NOMI.PEC)
  }

  if (contesto[CHIAVI_CONTESTO_VALIDAZIONE.OBBLIGO_NASCITA] === true) {
    validaPersonaNascita(getUtilValidazioneAnnidato('nascita'))
  }
}



const NOMI_NASCITA = {
  DATA: 'dataNascita',
  CAP: 'luogo.cap',
  COMUNE: 'luogo.comune',
  PROVINCIA: 'luogo.provincia',
  NAZIONE: 'luogo.nazione'
}

export function FormNascita(props) {
  const { nazioni } = props

  return (
    <BaseGridLayout disposizioneRighe={[1, 1]}>
      <BaseDateInput
        label='Data di nascita' name={NOMI_NASCITA.DATA}
      />
      <ComuneModifica
        nameCap={NOMI_NASCITA.CAP}
        nameComune={NOMI_NASCITA.COMUNE}
        nameProvincia={NOMI_NASCITA.PROVINCIA}
        nameNazione={NOMI_NASCITA.NAZIONE}
        nazioni={nazioni}
        tipoRicercaNascita
        nascondiCampoCap
        larghezzaDefaultProvinciaENazione
      />
    </BaseGridLayout>
  )
}

export function trasformaValoriInputNascita(valori) {
  const { luogo: { nazione } } = valori

  if (isItaly(nazione)) {
    // A volte arriva 'Italia' anziché 'Italy'
    // Uniformo il valore così la select mostra il valore corretto
    return {
      ...valori,
      luogo: { ...valori.luogo, nazione: ITALY }
    }
  }

  return valori
}

function validaPersonaNascita(utilValidazione) {
  const { dateValide, required, valoriInput } = utilValidazione
  const { luogo: { nazione } } = valoriInput

  dateValide(NOMI_NASCITA.DATA)

  required('Data nascita non inserita', NOMI_NASCITA.DATA)
  required('Comune nascita non inserito', NOMI_NASCITA.COMUNE)
  if (isItaly(nazione)) {
    required('Provincia nascita non inserita', NOMI_NASCITA.PROVINCIA)
  }
  required('Nazione nascita non inserita', NOMI_NASCITA.NAZIONE)
}