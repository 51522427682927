import { useState } from 'react'
import { object, node, bool } from 'prop-types'
import { BaseCheckbox, BaseTextInput } from 'inputs'
import { BaseGridLayout } from 'layout'
import { BaseTitolo } from 'text'
import { CHIAVI_CONTESTO_VALIDAZIONE } from '../ContestoValidazione'
import { ENTITA_BASE } from '../EntitaBase'
import { EntitaProvider, useEntita } from '../entitaGenerica/components/EntitaContext'
import FormModificaEntita, { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'
import ScatolaEntita from '../entitaGenerica/components/ScatolaEntita'
import IndirizzoModifica, { validaIndirizzo } from '../indirizzo/IndirizzoModifica'
import ContattiModifica, { validaPersonaContatti } from './ContattiModifica'
import DatiAnagraficiModifica, { validaPersonaDatiAnagrafici } from './DatiAnagraficiModifica'
import DocumentoModifica, { validaPersonaDocumento } from './DocumentoModifica'
import IndirizzoDomicilioModifica from './IndirizzoDomicilioModifica'
import Persona from './Persona'
import RecapitiAggiuntiviModifica from './RecapitiAggiuntiviModifica'

PersonaModifica.propTypes = {
  ...PROP_TYPES_WRAPPER_FORM_MODIFICA,
  visibilita: object,
  BottoneTornaIndietro: node,
  readOnly: bool,
  propsDocumentoModifica: object,
  /*
    La modalita light nasconde alcuni campi:
    - minorenne
    - stato civile
    - professione
    - categoria patente
    - ente rilascio patente
    - data abilitazione patente
    - certificato cqc
    - patente revocata
    - patente sospesa
    - senza fissa dimora
  */
  modalitaLight: bool
}

PersonaModifica.defaultConfig = {
  path: 'persona',
  nomeEntita: ENTITA_BASE.PERSONA,
  url: 'persona',
  label: 'Anagrafica',
  validate: validaPersona
}

const PROPRIETA = {
  CONTATTI: 'contatti',
  DOCUMENTO: 'documento',
  SENZA_FISSA_DIMORA: 'senzaFissaDimora',
  INDIRIZZO_RESIDENZA: 'indirizzoResidenza',
  INDIRIZZO_DOMICILIO: 'indirizzoDomicilio',
  RECAPITI_AGGIUNTIVI: 'recapitiAggiuntivi',
  LUOGO_LAVORO: 'luogoLavoro',
}

const SEZIONI = {
  ...PROPRIETA,
  DATI_ANAGRAFICI: 'datiAnagrafici'
}

PersonaModifica.SEZIONI = SEZIONI

const VISIBILITA_DEFAULT = {
  [SEZIONI.DATI_ANAGRAFICI]: true,
  [SEZIONI.DOCUMENTO]: true,
  [SEZIONI.SENZA_FISSA_DIMORA]: true,
  [SEZIONI.INDIRIZZO_RESIDENZA]: true,
  [SEZIONI.INDIRIZZO_DOMICILIO]: true,
  [SEZIONI.CONTATTI]: true,
  [SEZIONI.LUOGO_LAVORO]: false,
  [SEZIONI.RECAPITI_AGGIUNTIVI]: false
}

export default function PersonaModifica(props) {
  const {
    propsEntita,
    label,
    isSubPanel,
    visibilita,
    BottoneTornaIndietro,
    readOnly,
    propsDocumentoModifica,
    modalitaLight
  } = props

  const { valoriInput, pathEntita } = propsEntita

  const visibilitaDefinitiva = {
    ...VISIBILITA_DEFAULT,
    ...(modalitaLight && {
      [SEZIONI.SENZA_FISSA_DIMORA]: false
    }),
    ...visibilita,
    ...(valoriInput?.senzaFissaDimora && {
      [SEZIONI.INDIRIZZO_RESIDENZA]: false,
      [SEZIONI.INDIRIZZO_DOMICILIO]: false,
    })
  }

  let propsEntitaProvider = useEntita()
  if (readOnly) {
    propsEntitaProvider = {
      ...propsEntitaProvider,
      gestionePermessi: {
        ...propsEntitaProvider.gestionePermessi,
        readOnly
      }
    }
  }

  const [keyRefreshForm, setKeyRefreshForm] = useState(Date.now())

  if (isSubPanel) {
    return (
      <EntitaProvider propsInterne={propsEntitaProvider}>
        <ScatolaEntita {...props}>
          {valoriInput &&
            <Persona persona={valoriInput} visioneRiassuntiva />
          }
        </ScatolaEntita>
      </EntitaProvider>
    )
  }


  const {
    gestioneModifica: { modificaEntita }
  } = propsEntitaProvider

  function importaPersona(nuovaPersona) {
    modificaEntita(
      ENTITA_BASE.PERSONA,
      pathEntita,
      nuovaPersona,
      // Invio già il valore fuso, quindi non serve farlo sopra
      { fondiConValoreAttuale: false }
    )
    setTimeout(() => setKeyRefreshForm(Date.now()), 250)
  }

  return (
    <EntitaProvider propsInterne={propsEntitaProvider} key={keyRefreshForm}>
      {BottoneTornaIndietro}

      {label && <BaseTitolo medio>{label}</BaseTitolo>}

      <BaseGridLayout vertical spacing={3}>
        {visibilitaDefinitiva[SEZIONI.DATI_ANAGRAFICI] &&
          <DatiAnagraficiModifica
            propsEntita={{
              valoriInput,
              nomeEntita: ENTITA_BASE.PERSONA,
              pathEntita
            }}
            label='Dati anagrafici'
            importaPersona={importaPersona}
            modalitaLight={modalitaLight}
          />
        }

        {visibilitaDefinitiva[SEZIONI.DOCUMENTO] &&
          <DocumentoModifica
            propsEntita={{
              valoriInput: valoriInput[PROPRIETA.DOCUMENTO],
              nomeEntita: ENTITA_BASE.PERSONA_DOCUMENTO,
              pathEntita: pathEntita.addPath(PROPRIETA.DOCUMENTO)
            }}
            label='Documento / Identificazione'
            importaPersona={importaPersona}
            modalitaLight={modalitaLight}
            {...propsDocumentoModifica}
          />
        }

        {visibilitaDefinitiva[SEZIONI.SENZA_FISSA_DIMORA] &&
          <FormModificaEntita {...propsEntita} ritardoDebounceInMillisecondi={250}>
            <BaseCheckbox
              label='Senza fissa dimora' name={PROPRIETA.SENZA_FISSA_DIMORA}
            />
          </FormModificaEntita>
        }

        {visibilitaDefinitiva[SEZIONI.INDIRIZZO_RESIDENZA] &&
          <IndirizzoModifica
            propsEntita={{
              valoriInput: valoriInput[PROPRIETA.INDIRIZZO_RESIDENZA],
              nomeEntita: ENTITA_BASE.INDIRIZZO,
              pathEntita: pathEntita.addPath(PROPRIETA.INDIRIZZO_RESIDENZA)
            }}
            label='Indirizzo di residenza'
            modalitaLight
          />
        }

        {visibilitaDefinitiva[SEZIONI.INDIRIZZO_DOMICILIO] &&
          <IndirizzoDomicilioModifica
            propsEntita={{
              valoriInput: valoriInput[PROPRIETA.INDIRIZZO_DOMICILIO],
              nomeEntita: ENTITA_BASE.INDIRIZZO,
              pathEntita: pathEntita.addPath(PROPRIETA.INDIRIZZO_DOMICILIO)
            }}
            label='Indirizzo di domicilio'
          />
        }

        {visibilitaDefinitiva[SEZIONI.CONTATTI] &&
          <ContattiModifica
            propsEntita={{
              valoriInput: valoriInput[PROPRIETA.CONTATTI],
              nomeEntita: ENTITA_BASE.PERSONA_CONTATTI,
              pathEntita: pathEntita.addPath(PROPRIETA.CONTATTI)
            }}
            propsEntitaPersona={{
              valoriInput,
              nomeEntita: ENTITA_BASE.PERSONA,
              pathEntita
            }}
            label='Contatti'
          />
        }

        {visibilitaDefinitiva[SEZIONI.RECAPITI_AGGIUNTIVI] &&
          <RecapitiAggiuntiviModifica
            propsEntita={{
              valoriInput: valoriInput[PROPRIETA.RECAPITI_AGGIUNTIVI],
              nomeEntita: ENTITA_BASE.PERSONA_RECAPITI_AGGIUNTIVI,
              pathEntita: pathEntita.addPath(PROPRIETA.RECAPITI_AGGIUNTIVI)
            }}
            label='Contatti / Recapiti'
          />
        }

        {visibilitaDefinitiva[SEZIONI.LUOGO_LAVORO] &&
          <FormModificaEntita {...propsEntita}>
            <BaseTextInput
              label='Luogo di attività lavorativa abituale'
              name={PROPRIETA.LUOGO_LAVORO}
              multiline rows={2} larghezza={40}
            />
          </FormModificaEntita>
        }
      </BaseGridLayout>
    </EntitaProvider>
  )
}



export function validaPersona(utilValidazione) {
  const { valoriInput, getUtilValidazioneAnnidato, contesto } = utilValidazione
  const { indirizzoDomicilio, senzaFissaDimora } = valoriInput

  validaPersonaDatiAnagrafici(utilValidazione)

  if (contesto[CHIAVI_CONTESTO_VALIDAZIONE.OBBLIGO_DOCUMENTO] === true) {
    validaPersonaDocumento(getUtilValidazioneAnnidato(PROPRIETA.DOCUMENTO))
  }

  if (!senzaFissaDimora && contesto[CHIAVI_CONTESTO_VALIDAZIONE.OBBLIGO_RESIDENZA] === true) {
    validaIndirizzo(getUtilValidazioneAnnidato(PROPRIETA.INDIRIZZO_RESIDENZA))
    if (indirizzoDomicilio) {
      validaIndirizzo(getUtilValidazioneAnnidato(PROPRIETA.INDIRIZZO_DOMICILIO))
    }
  }

  validaPersonaContatti(getUtilValidazioneAnnidato(PROPRIETA.CONTATTI))
}