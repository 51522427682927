import { OPERAZIONI_BASE } from '../network/OperazioniBase'

async function elencoPromessaStandard(configElenco) {
  const {
    chiave,
    operationId = OPERAZIONI_BASE.DIZIONARIO_GET,
    inviaOperazione,
    dizionario,
    params = null,
    operationData: operationData_Config = null,
    operationOptions: operationOptions_Config = null,
    trasformaElenco: trasformaElenco_Config = data => data
  } = configElenco

  let operationData
  let trasformaElenco
  const operationOptions = { ...operationOptions_Config }

  if (operationId === OPERAZIONI_BASE.DIZIONARIO_GET) {
    const nome = dizionario || chiave
    operationData = { nome, ...(params && { params }) }
    trasformaElenco = data => trasformaElenco_Config(data[nome])
  } else {
    operationData = operationData_Config
    trasformaElenco = trasformaElenco_Config
  }

  const { ok, data, error } = await inviaOperazione(operationId, operationData, operationOptions)
  if (ok) return { ok: true, data: trasformaElenco(data) }
  return { ok: false, error: error.messaggi[0]?.msg }
}

function elencoEntityTipologia(entity, opzioni = {}) {
  const {
    aggiungiOpzioneVuota = true
  } = opzioni

  return {
    dizionario: 'entityTipologia',
    params: { entity },
    trasformaElenco: tipologie => ([
      ...(aggiungiOpzioneVuota ? [{ value: '', label: '' }] : []),
      ...tipologie.map(({ id, nome, codice }) => ({
        value: String(id),
        label: nome,
        codice
      }))
    ]),
    legatoAlComando: true
  }
}

function elencoStatiCustom(entity) {
  return {
    dizionario: 'stato-custom',
    params: { entity },
    legatoAlComando: true
  }
}

function elencoStrumenti(tipo) {
  return {
    dizionario: 'strumenti',
    params: tipo ? { tipo } : {},
    legatoAlComando: true
  }
}

export {
  elencoPromessaStandard,
  elencoEntityTipologia,
  elencoStatiCustom,
  elencoStrumenti
}