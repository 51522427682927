import { Fragment, useState } from 'react'
import { node } from 'prop-types'
import { BaseGridLayout } from 'layout'
import { BaseTitolo } from 'text'
import { ENTITA_BASE } from '../EntitaBase'
import { PROP_TYPES_WRAPPER_FORM_MODIFICA } from '../entitaGenerica/components/FormModificaEntita'
import ListaSubPanels from '../entitaGenerica/components/ListaSubPanels'
import ScatolaEntita from '../entitaGenerica/components/ScatolaEntita'
import DatiGeneraliModifica from './DatiGeneraliModifica'
import Veicolo from './Veicolo'

VeicoloModifica.propTypes = {
  ...PROP_TYPES_WRAPPER_FORM_MODIFICA,
  BottoneTornaIndietro: node
}

export default function VeicoloModifica(props) {
  const {
    propsEntita,
    label,
    isSubPanel,
    subPanels,
    BottoneTornaIndietro,
    propsInterne_PannelloModificaEntita: {
      gestioneModifica: { modificaEntita }
    }
  } = props

  const [keyRefreshForm, setKeyRefreshForm] = useState(Date.now())

  if (isSubPanel) {
    const veicolo = propsEntita.valoriInput
    return (
      <ScatolaEntita {...props}>
        {veicolo &&
          <Veicolo veicolo={veicolo} visioneRiassuntiva />
        }
      </ScatolaEntita>
    )
  }

  function importaVeicolo(nuovoVeicolo) {
    modificaEntita(
      ENTITA_BASE.VEICOLO,
      propsEntita.pathEntita,
      nuovoVeicolo,
      // Invio già il valore fuso, quindi non serve farlo sopra
      { fondiConValoreAttuale: false }
    )
    setTimeout(() => setKeyRefreshForm(Date.now()), 250)
  }

  return (
    <Fragment key={keyRefreshForm}>
      {BottoneTornaIndietro}

      <BaseTitolo medio>Veicolo - {label}</BaseTitolo>

      <BaseGridLayout vertical spacing={3}>
        <DatiGeneraliModifica
          propsEntita={propsEntita}
          label='Dati generali'
          importaVeicolo={importaVeicolo}
        />

        <ListaSubPanels subPanels={subPanels} />
      </BaseGridLayout>
    </Fragment>
  )
}