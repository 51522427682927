import { List, Box } from '@mui/material'
import { BaseTooltip } from 'feedback'
import { ICONE } from 'icons'
import { BaseIconButtons } from 'inputs'
import { BaseDivider, BaseGridLayout, useDrawer } from 'layout'
import { BaseLinkPrimoLivello } from 'navigation'
import { BaseTesto } from 'text'
import { useCurrentTheme } from 'utils'
import { useIsRottaCorrenteSenzaComando } from '../navigation/utils'
import { useUser } from '../userManagement/userProvider/UserProvider'
import LinkHomeIcon from '../userManagement/LinkHomeIcon'

export default function DrawerNavigazione() {
  const { isDefaultTheme } = useCurrentTheme()

  const { routesVisibiliNeiMenu_VisibiliDalloUser, isUserStaff } = useUser()

  const isRottaCorrenteSenzaComando = useIsRottaCorrenteSenzaComando()

  const { drawerOpen, toggleDrawerOpen, appBarHeight } = useDrawer()

  const sx_ListItemNavigazione = () => {
    return { px: 1, lineHeight: 'normal' }
  }

  return (
    <div className={drawerOpen ? 'MuiDrawer-open' : ''}>
      <Box
        sx={({ spacing }) => ({
          display: 'flex',
          alignItems: 'center',
          padding: spacing(0, 1),
          // necessary for content to be below app bar
          minHeight: spacing(appBarHeight),
          justifyContent: 'space-between',
          className: 'MuiDrawer-HomeLink',
          borderRight: '1px solid #ffffff4a'
        })}
        className='MuiDrawer-HomeLink'
      >
        <LinkHomeIcon sx={{ mx: 2, display: { xs: 'block', sm: 'none' } }} />

        {drawerOpen &&
          <BaseIconButtons.Minimizza
            onClick={toggleDrawerOpen}
            contenutoTooltip={drawerOpen ? 'Chiudi menu' : 'Apri menu'}
            color='inherit'
            edge='start'
            sx={{}}
          />
        }
      </Box>

      {isDefaultTheme() && <BaseDivider />}

      {routesVisibiliNeiMenu_VisibiliDalloUser.length > 0 &&
        <List disablePadding className='menu-laterale'>
          {routesVisibiliNeiMenu_VisibiliDalloUser.map(route =>
            <BaseLinkPrimoLivello
              key={route.pathAssoluto}
              route={route}
              sx_ListItemNavigazione={sx_ListItemNavigazione}
              disableTooltipIcona={drawerOpen}
              disableSubListOpen={!drawerOpen}
            />
          )}
        </List>
      }

      {isUserStaff() && isRottaCorrenteSenzaComando &&
        <Box sx={{ mt: 4, px: 0.45, pt: 4, position: 'fixed', bgcolor: '#ffa0a0' }}>
          <BaseGridLayout vertical spacing={4}>
            {Array(15).fill().map((_, index) =>
              <BaseTooltip
                contenuto='Attenzione: la pagina corrente è riservata a Staff per eseguire operazioni su tutti i comandi'
                placement='right'
                key={index}
              >
                <Box sx={{ textAlign: 'center' }}>
                  <ICONE.LOGO_INCLOUD_MINI />
                  <BaseTesto sx={{ fontSize: '0.7rem' }} variant='subtitle2'>
                    Sovra
                    <br />
                    Comando
                  </BaseTesto>
                </Box>
              </BaseTooltip>
            )}
          </BaseGridLayout>
        </Box>
      }
    </div>
  )
}
